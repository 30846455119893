import { AuthContext } from "context";
import { getUserMe } from "service";
import { getUserForStorage } from "utils";
import { useContext } from "react";
import { useQuery } from "react-query";
import { IUser } from "interfaces/IUser";
import { getCurrentUser } from "../utils";

function convertJsonToIUser(jsonData: any, defaultAddress: any ): IUser {
  return {
    id: jsonData.id,
    first_name: jsonData.name.first,
    last_name: jsonData.name.last,
    role: jsonData.roles[0],
    roles: jsonData.roles,
    phone: jsonData.phone,
    email: jsonData.email,
    locationNote: defaultAddress ? defaultAddress.locationNote : "",
    default_address_name: defaultAddress ? defaultAddress.name : "",
    default_address_id: defaultAddress ? defaultAddress._id : "",
    operationHours: defaultAddress && defaultAddress.operationHours ? defaultAddress.operationHours : [],
    payout: mapUserPayout(jsonData),
    country: jsonData.country ? jsonData.country : 'ca'
  } as IUser;
};

function mapUserPayout(jsonData: any){
  const payout = jsonData.payout ? jsonData.payout : null;
  return {
    account: payout.account ? payout.account : null,
    bank: payout.bank ? payout.bank : null,
    currency: payout.currency ? payout.currency : null,
    status: payout.status ? payout.status : null,
  }
}


export const useUser = () => {
  const { setUser } = useContext(AuthContext);
  const userQuery = useQuery<any, Error>("user", getUserMe.bind(this), {
    enabled: false,
    onSuccess: (data) => {
      if (!data) return;
      const oldJsonUser = convertJsonToIUser(data.user, data.defaultAddress);
      console.log("getUserMe", oldJsonUser);
      const user = getUserForStorage(oldJsonUser);
      setUser(user);
    },
  });

  return userQuery;
};

export const updateUserInStorage = (toUpdateAtt: any) => {
  const currUserFromStorage = getCurrentUser();
  if (!currUserFromStorage) {
    return;
  }
  if (!toUpdateAtt) {
    return;
  }
  const userToStore = { ...currUserFromStorage, ...toUpdateAtt };
  localStorage.setItem("user@store", JSON.stringify(userToStore));
  console.log("user updated in storage", toUpdateAtt);
};
