import React, {useState, useEffect} from 'react';
// import {useStripe} from '@stripe/react-stripe-js';

import { SuccessDialog } from "../SuccessDialog";
import { useMutation } from 'react-query';
import { IOrderPost } from '@interfaces';
import { postOrder } from 'service';
import LoadingIndicator from 'components/LoadingIndicator';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const OrderCreationStepThree = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const postOrderMutation = useMutation<Response, Error, IOrderPost>(
    postOrder,
    {
      onSuccess: (_data, variables) => {
        setShowStep3(true);
      },
      onError: (error) => {
        throw error;
      },
    }
  );


  // const [message, setMessage] = useState<string|null>(null);
  // const [paymentStatus, setPaymentStatus] = useState<string|null>(null);
  
  const [showStep3, setShowStep3] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const handleClose = (isLastStep = false) => {
    setShowStep3(false);
    if (isLastStep) {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}`
      );
    }
  };

  const getUrlParameter = (parameterName: string) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(parameterName);
  }

  
  const payload: any = {
    store: getUrlParameter('store'),
    carrier: getUrlParameter('carrier'),
    tracking_number: getUrlParameter('tracking_number'),
    expectedArrival: getUrlParameter('expectedArrival'),
    address: getUrlParameter('address'),
    package_size: getUrlParameter('package_size'),
    notes: getUrlParameter('notes'),
  }

  const handleRequest = () => {
    const urlParams = new URLSearchParams(payload);
    postOrderMutation.mutateAsync(payload)
    .then(res => {
      setShowStep3(true);
    })
    .catch((error) => {
      enqueueSnackbar('Failed to process payment details. Please try another payment method.', {
        variant: "error",
      });
      navigate(`/OrderCreationStepTwo/${getUrlParameter('addressId')}?${urlParams.toString()}`)
    });
  }

  useEffect(() => {
    setIsLoading(true);
    handleRequest();
  }, []);

  
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <div>
        <SuccessDialog
          open={showStep3}
          handleClose={() => handleClose(true)}
          dialogTitle={"Order Submitted"}
          dialogText={'The Holder has been notified of your delivery and is now expecting your package. When the package arrives, the Holder will process the package and you will be notified.'}
          buttonText={"Return to main menu"}
          imgSrc={`${process.env.PUBLIC_URL}/order_submitted.png`}
        />
      </div>
    </>
  );
};



export { OrderCreationStepThree };

