import React, { useEffect, useState } from "react";

import { SuccessDialog } from "components/SuccessDialog";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { postOrder } from "service";
import { userEstimate } from "service/requests";

import { IOrderPost } from "@interfaces";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  StyledTypography,
  StyledTypography10,
  StyledTypography7,
  StyledTypography8,
  StyledTypography9,
} from "./style.js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
const usStripePromise = loadStripe(`${process.env.REACT_APP_US_STRIPE_PUBLISHABLE_KEY}`);

const useStyles = makeStyles((theme) => ({
  sendPackage: {
    "& .send": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.9rem",
      color: "#ff741e",
      "@media (max-width: 768px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem",
      },
    },

    "& .package": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.9rem",
      color: "primary",
      "@media (max-width: 768px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem",
      },
    },
  },
  label: {
    fontSize: "17px",
    color: "primary",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
    },
  },
  checkbox: {
    fontSize: "16px",
    color: "primary",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
    },
  },
}));

const OrderCreationStepTwo = () => {
  const { addressId } = useParams();

  const getUrlParameter = (parameterName: string) => {
    const params = new URLSearchParams(window.location.search);
    const param = params.get(parameterName);
    return param ? param : "";
  };

  const getPaymentParams = () => {
    const payload = {
      store: getUrlParameter("store"),
      carrier: getUrlParameter("carrier"),
      tracking_number: getUrlParameter("tracking_number"),
      expectedArrival: getUrlParameter("expectedArrival"),
      address: getUrlParameter("address"),
      package_size: getUrlParameter("package_size"),
      notes: getUrlParameter("notes"),
      addressId: getUrlParameter("addressId"),
      setupIntent: getUrlParameter("setupIntent"),
      customer: getUrlParameter("customer"),
      ephemeralKey: getUrlParameter("ephemeralKey"),
    };

    let count = 0;
    Object.values(payload).forEach((val) => {
      if (val && val !== "") {
        count++;
      }
    });

    if (count) {
      return payload;
    }

    return null;
  };

  const [showStep3, setShowStep3] = React.useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const [paymentPrams, setPaymentParams] = React.useState<any | null>(
    getPaymentParams()
  ); // TODO add the payment params
  const [isConfirmed, setIsConfirmed] = useState(
    getPaymentParams() ? true : false
  ); // TODO set the value based on url params

  const handleClose = (isLastStep = false) => {
    setShowStep3(false);
    if (isLastStep) {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}`
      );
    }
  };

  /*const handlePreviousStep = () => {
    setOpenStep1(true);
    setOpenStep2(false);
  };*/

  const postOrderMutation = useMutation<Response, Error, IOrderPost>(
    postOrder,
    {
      onSuccess: (_data, variables) => {
        setShowStep3(true);
      },
      onError: (error) => {
        throw error;
      },
    }
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    if (
      isChecked &&
      companyName.trim() !== ""
      //!isNaN(parseFloat(quantity)) &&
      // parseFloat(quantity) > 0
    ) {
      setIsLoading(true);
      let selectedExpectedArrival = new Date();
      const sameNextDay = document.getElementById("sameNextDay");
      const twoToSixDays = document.getElementById("2-6days");
      const sevenToTwelveDays = document.getElementById("7-12days");
      const twelvePlusDays = document.getElementById("12+days");
      let currentDate = new Date();

      if (sameNextDay && (sameNextDay as HTMLInputElement).checked) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 24 * 60 * 60 * 1000
        );
      } else if (twoToSixDays && (twoToSixDays as HTMLInputElement).checked) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 6 * 24 * 60 * 60 * 1000
        ); // Add 6 days
      } else if (
        sevenToTwelveDays &&
        (sevenToTwelveDays as HTMLInputElement).checked
      ) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 12 * 24 * 60 * 60 * 1000
        );
      } else if (
        twelvePlusDays &&
        (twelvePlusDays as HTMLInputElement).checked
      ) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 13 * 24 * 60 * 60 * 1000
        );
      }

      console.log("Data to be sent to API:", {
        store: companyName,
        carrier: shippingInfo,
        tracking_number: trackingNumber,
        expectedArrival: selectedExpectedArrival.toISOString(),
        address: "",
        package_size: isLarge ? 16 : 15,
        notes: "",
      });

      const payload: any = {
        store: companyName,
        carrier: shippingInfo,
        tracking_number: trackingNumber,
        expectedArrival: selectedExpectedArrival.toISOString(),
        address: addressId,
        package_size: isLarge ? 16 : 15,
        notes: notes ? notes : "",
      };

      postOrderMutation
        .mutateAsync(payload)
        .then((res) => {
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.status === 480) {
            setPaymentParams({ ...error, ...payload, addressId: addressId });
            setIsConfirmed(true);
          } else {
            alert("Please fill in all the required fields before confirming.");
          }
          setIsLoading(false);
        });
    }
    // setIsConfirmed(true);
    // navigate(`/check-out`);
  };

  const [isChecked, setIsChecked] = React.useState(false);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const [companyName, setCompanyName] = React.useState(
    getUrlParameter("store")
  );
  //const [quantity, setQuantity] = React.useState("");
  const [shippingInfo, setShippingInfo] = React.useState(
    getUrlParameter("carrier")
  );
  const [trackingNumber, setTrackingNumber] = React.useState(
    getUrlParameter("tracking_number")
  );
  const [notes, setNotes] = React.useState(getUrlParameter("notes"));
  const [isLarge, setIsLarge] = React.useState(
    parseInt(getUrlParameter("package_size")) === 16 ? true : false
  );

  const [expectedArrival, setExpectedArrival] = useState("");
  //const [quantityError, setQuantityError] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);
  const [estimateData, setEstimateData] = useState<any>(null);

  const roundedInputStyle = {
    borderRadius: 10,
    border: "0.1px solid black",
    backgroundColor: "#f8f8f8",
    fontSize: "16.2px",
    color: "primary",
  };

  if (window.innerWidth <= 768) {
    roundedInputStyle.fontSize = "0.9rem";
  }
  if (window.innerWidth <= 414) {
    roundedInputStyle.fontSize = "0.76rem";
  }

  const requiredIconStyle = {
    color: "primary",
    fontSize: "0.4rem",
    verticalAlign: "top",
  };

  useEffect(() => {
    const fetchEstimate = async () => {
      try {
        if (addressId) {
          const data = await userEstimate(addressId);
          setEstimateData(data);
          console.log("Fetched user estimate data:", data);
        }
      } catch (error) {
        console.error("Error fetching estimate:", error);
      }
    };

    if (isLarge || !isLarge) {
      fetchEstimate();
    }
  }, [isLarge, addressId]);

  /*const handleQuantityChange = (event) => {
    const inputQuantity = event.target.value;

    if (!isNaN(inputQuantity) && inputQuantity <= 3 && inputQuantity >= 0) {
      setQuantity(inputQuantity);
      setQuantityError("");
    } else if (inputQuantity < 0) {
      setQuantityError("Quantity should be a positive number.");
    } else {
      setQuantity(inputQuantity);
      setQuantityError("Quantity should be 3 or less.");
    }
  };*/

  useEffect(() => {
    const isComplete: boolean = companyName !== "";
    //quantity.toString() !== "" &&
    //parseFloat(quantity) > 0;
    setRequiredFieldsComplete(isComplete);
  }, [companyName /*quantity*/]);

  const isConfirmDisabled = !requiredFieldsComplete || !isChecked;

  const debug = () => {
    console.log("companyName", companyName);
    //console.log("quantity", quantity);
    console.log("shippingInfo", shippingInfo);
    console.log("trackingNumber", trackingNumber);
    console.log("notes", notes);
    console.log("expectedArrival: ", expectedArrival);
  };
  debug();

  /*function convertToAMPM(time) {
    const [hours, minutes] = time.split(":");
    let ampm = "AM";

    let formattedHours = parseInt(hours);
    if (formattedHours >= 12) {
      ampm = "PM";
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    }

    return `${formattedHours}:${minutes}${ampm}`;
  }*/
  console.log("isConfirmed", isConfirmed);
  console.log("stripePromise", stripePromise);

  return (
    <>
      <DialogTitle
        className={classes.sendPackage}
        align="center"
        style={{ marginTop: "20px" }}
      >
        <span className="send">Send </span>
        <span className="package">Package</span>
      </DialogTitle>

      <StyledTypography
        align="center"
        sx={{
          paddingTop: isSmallScreen ? 1 : isMediumScreen ? 1 : 1.5,
          paddingLeft: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
          paddingRight: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
        }}
      >
        Step 2 of 2
      </StyledTypography>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ padding: "20px" }}>
          <Grid container justifyContent="center" marginTop={"5%"}>
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                label="Which company is this package from?"
                type="string"
                fullWidth
                variant="standard"
                helperText="e.g. Amazon, Best Buy, etc."
                value={companyName}
                sx={{ marginBottom: "10px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setCompanyName(event.target.value)}
              />
              {/*} <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              required
              margin="dense"
              id="number"
              label="Quantity of items (max 3 items):"
              type="number"
              fullWidth
              variant="standard"
              value={quantity}
              onChange={handleQuantityChange}
              sx={{ marginBottom: "25px" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {quantityError && (
              <p style={{ color: "#ff741e", marginLeft: "10px" }}>
                {quantityError}
              </p>
            )}
            </div>*/}
              <Typography variant="body2">
                Approximate order size? <StarIcon style={requiredIconStyle} />
              </Typography>
              <RadioGroup aria-label="package-size" name="package-size">
                <FormControlLabel
                  value="small"
                  control={<Radio />}
                  label={
                    <StyledTypography7>
                      Small package (≤ 35 lbs or 16 kgs)
                    </StyledTypography7>
                  }
                  checked={!isLarge}
                  onChange={() => setIsLarge(false)}
                />
                <FormControlLabel
                  value="large"
                  control={<Radio />}
                  label={
                    <StyledTypography7>
                      Large package (<span>&gt;</span> 35 lbs or 16 kgs)
                    </StyledTypography7>
                  }
                  checked={isLarge}
                  onChange={() => setIsLarge(true)}
                />
              </RadioGroup>
              <StyledTypography8>
                Holders have the option to leave packages over 35 lbs or 16 kgs
                outside. Examples of 35lbs or more: desktop computer, bag of
                cement, car battery, baby stroller.
              </StyledTypography8>
              <StyledTypography9>
                Additional <strong>optional</strong> package information:
              </StyledTypography9>
              <TextField
                margin="dense"
                id="name"
                label="Shipping / courier (if known)"
                type="string"
                fullWidth
                variant="standard"
                value={shippingInfo}
                onChange={(event) => setShippingInfo(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="dense"
                id="name"
                label="Order or reference # (if known)"
                type="string"
                fullWidth
                variant="standard"
                value={trackingNumber}
                onChange={(event) => setTrackingNumber(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography style={{ fontSize: "11px", marginTop: "10px" }}>
                Expected Arrival
              </Typography>
              <RadioGroup aria-label="expected-arrival" name="expected-arrival">
                <FormControlLabel
                  value="sameNextDay"
                  control={<Radio />}
                  label={<StyledTypography7>Same/Next Day</StyledTypography7>}
                  checked={expectedArrival === "sameNextDay"}
                  onChange={() => setExpectedArrival("sameNextDay")}
                />
                <FormControlLabel
                  value="2-6days"
                  control={<Radio />}
                  label={<StyledTypography7>2-6 Days</StyledTypography7>}
                  checked={expectedArrival === "2-6days"}
                  onChange={() => setExpectedArrival("2-6days")}
                />
                <FormControlLabel
                  value="7-12days"
                  control={<Radio />}
                  label={<StyledTypography7>7-12 Days</StyledTypography7>}
                  checked={expectedArrival === "7-12days"}
                  onChange={() => setExpectedArrival("7-12days")}
                />
                <FormControlLabel
                  value="12+days"
                  control={<Radio />}
                  label={<StyledTypography7>12+ Days</StyledTypography7>}
                  checked={expectedArrival === "12+days"}
                  onChange={() => setExpectedArrival("12+days")}
                />
              </RadioGroup>
              <TextField
                margin="dense"
                id="name"
                label="Additional notes for Holder"
                type="string"
                fullWidth
                variant="standard"
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {estimateData && (
                <div>
                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                    Estimated Total
                  </Typography>
                  <Typography style={{ fontSize: "11px", marginTop: "10px" }}>
                    Regular delivery: $
                    {Number.isInteger(estimateData.estimate.delivery)
                      ? estimateData.estimate.delivery
                      : estimateData.estimate.delivery.toFixed(2)}{" "}
                    <br />
                    Third-party processing fee: $
                    {Number.isInteger(estimateData.estimate["3rdParty"])
                      ? estimateData.estimate["3rdParty"]
                      : estimateData.estimate["3rdParty"].toFixed(2)}{" "}
                    <br />
                    Administrative fee: $
                    {Number.isInteger(estimateData.estimate.admin)
                      ? estimateData.estimate.admin
                      : estimateData.estimate.admin.toFixed(2)}{" "}
                    <br />
                    Total: $
                    {Number.isInteger(estimateData.estimate.total)
                      ? estimateData.estimate.total
                      : estimateData.estimate.total.toFixed(2)}
                  </Typography>
                </div>
              )}

              <FormControlLabel
                sx={{
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={
                  <StyledTypography10>
                    I agree to the <Link href="#">Terms of Use</Link>, which
                    includes agreeing to not ship anything illegal, prohibited,
                    or hazardous to this location. For more information on
                    prohibited items, <Link href="#">click here</Link>.
                  </StyledTypography10>
                }
              />
              {!isConfirmed && !paymentPrams && (
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ m: 1, position: "relative" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      background: isChecked ? "#ff741e" : "#ffffff",
                      color: isChecked ? "#ffffff" : "#ff741e",
                      "&:hover": {
                        backgroundColor: "#FF9858",
                      },
                      fontWeight: 500,
                      fontSize: "0.95rem",
                      width: "50%",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                      fontFamily: "Rubik, sans-serif",
                      marginBottom: "30px",
                      borderRadius: "5px",
                      "@media (max-width: 768px)": {
                        fontSize: "0.8rem",
                        marginTop: "5px",
                      },
                      "@media (max-width: 480px)": {
                        fontSize: "0.53rem",
                        marginTop: "0px",
                      },
                    }}
                    onClick={handleConfirm}
                    color={!isConfirmDisabled ? "primary" : "secondary"}
                    disabled={isConfirmDisabled || isLoading}
                  >
                    Confirm & Send
                  </Button>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#000",
                        position: "absolute",
                        top: "30%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      {isConfirmed && paymentPrams && (
        <Grid
          sx={{
            padding: "16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box display="flex" justifyContent="center">
          {
          (paymentPrams.country === 'us')
            ? (
              <Elements
              stripe={usStripePromise}
              options={{
                clientSecret: paymentPrams?.setupIntent,
                customerOptions: {
                  customer: paymentPrams!.customer,
                  ephemeralKey: paymentPrams!.ephemeralKey,
                },
              }}
            >
              <CheckoutForm {...paymentPrams} />
            </Elements>
            )
            : (
              <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentPrams?.setupIntent,
                customerOptions: {
                  customer: paymentPrams!.customer,
                  ephemeralKey: paymentPrams!.ephemeralKey,
                },
              }}
            >
              <CheckoutForm {...paymentPrams} />
            </Elements>
            )
          }
          </Box>
        </Grid>
      )}
      <SuccessDialog
        open={showStep3}
        handleClose={() => handleClose(true)}
        dialogTitle={"Order Submitted"}
        dialogText={
          "The Holder has been notified of your delivery and is now expecting your package. When the package arrives, the Holder will process the package and you will be notified."
        }
        buttonText={"Return to main menu"}
        imgSrc={`${process.env.PUBLIC_URL}/order_submitted.png`}
      />
    </>
  );
};

const CheckoutForm = (paymentPrams: any) => {
  const { setupIntent: clientSecret } = paymentPrams;
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);

  const generateUrl = (params: Record<string, string>) => {
    const urlObj = new URL(
      `${process.env.REACT_APP_API_URL!}/OrderCreationStepThree`
    );
    const urlParams = new URLSearchParams(params);
    urlObj.search = urlParams.toString();
    return urlObj.toString();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }
    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      enqueueSnackbar(submitError.message, { variant: "error" });
      setIsLoading(false);
      return;
    }

    try {
      const { error } = await stripe!.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
          return_url: generateUrl(paymentPrams),
        },
      });
      // return_url: `${process.env.REACT_APP_API_URL!}/OrderCreationStepTwo/${addressId}?setupIntent=${clientSecret}&customer=${customer}`,

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      enqueueSnackbar(
        error && error.message
          ? error.message
          : "Something went wrong, please try again!",
        { variant: "error" }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box sx={{ m: 1, position: "relative" }}>
        <Button
          variant="contained"
          color={"primary"}
          disabled={!stripe || !elements || isLoading}
          type="submit"
          sx={{
            color: "#ffffff",
            marginTop: "20px",
            marginRight: "auto",
            marginLeft: "auto",
            display: "block",
          }}
        >
          Pay
        </Button>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: "#000",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </form>
  );
};

export { OrderCreationStepTwo, useStyles };
