import React, { useState } from 'react';

import Autocomplete from 'react-google-autocomplete';

import { IAddressPost } from '@interfaces';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import {
  aliasName,
  getCurrentUser,
  isHolder,
} from '../../utils';

interface AddressSearchProps {
  onAddressSubmit: (addressData: IAddressPost) => void;
  addAddressOpen: boolean;
  setAddressOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& + .pac-container": {
      zIndex: "1300",
    },
  },
  addAdress: {
    "& .add": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.5rem",
      color: "#ff741e",
      "@media (max-width: 768px)": {
        fontSize: "1.3rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.1rem",
      },
    },

    "& .address": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.5rem",
      color: "primary",
      "@media (max-width: 768px)": {
        fontSize: "1.3rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.1rem",
      },
    },
  },
}));

type Option = {
  label: string;
  value: number | null;
};

const generateHourOptions = (): Option[] => {
  const options = [{ label: "Not Available", value: null }];
  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour % 12 || 12;
    const period = hour < 12 ? "AM" : "PM";
    const formattedString = `${formattedHour}:00 ${period}`;
    options.push({ label: formattedString, value: hour });
  }
  return options;
};

const hourOptions = generateHourOptions();

const AddAddress: React.FC<AddressSearchProps> = ({
  onAddressSubmit,
  addAddressOpen,
  setAddressOpen,
}) => {

  const [user] = useState(getCurrentUser());

  const [type, setType] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [placeId, setPlaceId] = useState(null);
  const [street, setStreet] = useState("");
  const [province, setProvince] = useState("");

  const [showFields, setShowFields] = useState(false);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const [operationHours, setOperationHours] = useState([
    { day: "monday", from: null, to: null },
    { day: "tuesday", from: null, to: null },
    { day: "wednesday", from: null, to: null },
    { day: "thursday", from: null, to: null },
    { day: "friday", from: null, to: null },
    { day: "saturday", from: null, to: null },
    { day: "sunday", from: null, to: null },
  ]);

  const [maxWeight, setMaxWeight] = useState(100);
  const [maxTotalLength, setMaxTotalLength] = useState(300);
  const [oversized, setOversized] = useState(false);

  const [maxHoldingDays, setmaxHoldingDays] = useState(3);
  const [holdingDays, setHoldingDays] = useState(false);


  const handleHourChange = (e, field, day) => {
    const newValue = (e.target.value = parseInt(e.target.value));
    setOperationHours((prevOperationHours) =>
      prevOperationHours.map((oh) =>
        oh.day === day ? { ...oh, [field]: newValue } : oh
      )
    );
  };

  const renderSelect = (day, field) => (
    <Select
      value={operationHours.find((oh) => oh.day === day)[field]}
      onChange={(e) => handleHourChange(e, field, day)}
      fullWidth
    >
      {hourOptions.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  const onClose = () => {
    setAddressOpen(false);
  };

  const isFormValid =
    street &&
    formattedAddress &&
    city &&
    province &&
    country &&
    postcode &&
    type;

  const onPlaceSelected = (place: any) => {
    console.log(place);

    setCity("");
    setStreet("");
    setProvince("");
    setPlaceId(null);
    setCountry("");
    setPostcode("");
    setLatitude(place.geometry.location.lat());
    setLongitude(place.geometry.location.lng());
    let streetNumber = "";
    let route = "";
    for (const component of place.address_components) {
      if (
        component.types.includes("street_number") ||
        component.types.includes("premise")
      ) {
        streetNumber = component.short_name;
      } else if (component.types.includes("route")) {
        route = component.short_name;
      } else if (component.types.includes("locality")) {
        setCity(component.long_name);
      } else if (component.types.includes("administrative_area_level_1")) {
        setProvince(component.long_name);
      } else if (
        !component.types.includes("administrative_area_level_1") &&
        component.types.includes("administrative_area_level_2")
      ) {
        setProvince(component.long_name);
      } else if (
        !component.types.includes("administrative_area_level_1") &&
        !component.types.includes("administrative_area_level_2") &&
        component.types.includes("sublocality")
      ) {
        if (!province) setProvince(component.long_name);
      } else if (component.types.includes("country")) {
        setCountry(component.long_name);
      } else if (
        component.types.includes("postal_code") ||
        component.types.includes("postal_code_prefix")
      ) {
        setPostcode(component.long_name);
      }
    }
    setStreet(`${streetNumber} ${route}`.trim());
    setPlaceId(place.place_id ? place.place_id : null);
    setFormattedAddress(place.formatted_address);
    setShowFields(true);
  };

  const onSubmit = () => {
    const user = getCurrentUser();
    const filteredOperationHours = operationHours.filter(
      (oh) => oh.from !== null && oh.to !== null
    );

    const submittedAddress = {
      user: user ? user.id : null,
      type: type,
      street: street,
      province: province,
      city: city,
      country: country,
      postal: postcode,
      latitude: latitude,
      longitude: longitude,
      operationHours: filteredOperationHours,
      formattedAddress: formattedAddress,
      googlePlaceId: placeId,
      name: aliasName(user),
      isHolder: isHolder(),
      maxWeight: maxWeight,
      maxTotalLength: maxTotalLength,
      maxHoldingDays: maxHoldingDays
    };
    onAddressSubmit(submittedAddress);
    console.log("submittedAddress", submittedAddress);
    onClose();
  };

  const handleOversizedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOversized(event.target.checked);
    if(event.target.checked === true){
      setMaxWeight(19);
      setMaxTotalLength(144);
    } else {
      setMaxWeight(100);
      setMaxTotalLength(300);
    }
  };

  const handleHoldingDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHoldingDays(event.target.checked);
    if(event.target.checked === true){
      setmaxHoldingDays(5);
    } else {
      setmaxHoldingDays(3);
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: isSmallScreen ? "100%" : "800px",
          height: "auto",
          borderRadius: "10px",
        },
      }}
      open={addAddressOpen}
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogTitle
        className={classes.addAdress}
        align="center"
        sx={{ mt: 1.6 }}
      >
        <span className="add">Add </span>
        <span className="address">new address</span>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          style={{
            width: isSmallScreen ? "282px" : "530px",
            marginBottom: "10px",
            height: "3.7rem",
          }}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          onPlaceSelected={(place) => onPlaceSelected(place)}
          options={{ types: ["address"], componentRestrictions: { country: (user.country ? user.country : 'ca') }}}
        />
        {showFields && (
          <>
            <InputLabel id="address-type-label">Address Type</InputLabel>
            <Select
              labelId="address-type-label"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              <MenuItem value="" disabled>
                Select a type
              </MenuItem>
              <MenuItem value="residential">Residential</MenuItem>
              <MenuItem value="commercial">Commercial</MenuItem>
            </Select>
            <TextField
              id="street"
              label="street and unit number"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              fullWidth
              required
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id="address"
              label="Address"
              value={formattedAddress}
              onChange={(e) => setFormattedAddress(e.target.value)}
              required
              fullWidth
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id="province"
              label="State/Province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              fullWidth
              required
              // disabled // Prevent editing for now
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id="locality"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
              required
              //disabled // Prevent editing for now
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id="country"
              label="Country/Region"
              value={country}
              fullWidth
              required
              //disabled // Prevent editing for now
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id="postcode"
              label="Postal code"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              fullWidth
              required
              style={{ marginBottom: "10px" }}
            />
            <FormControlLabel
              style={{ marginBottom: "10px" }}
              id="max-holdeing-days-package"
              label="Willing to hold the package for a longer period"
              control={
                <Checkbox
                  checked={holdingDays}
                  onChange={handleHoldingDaysChange}
                />
              }
            />
            <FormControlLabel
              style={{ marginBottom: "10px" }}
              id="accept-oversized-package"
              label="Accept oversized package"
              control={
                <Checkbox
                  checked={oversized}
                  onChange={handleOversizedChange}
                />
              }
            />
            {isHolder() && (
              <Grid container spacing={2}>
                {daysOfWeek.map((day) => (
                  <Grid item xs={6} key={day}>
                    <InputLabel>{day.toUpperCase()}</InputLabel>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        {renderSelect(day, "from")}
                      </Grid>
                      <Grid item xs={6}>
                        {renderSelect(day, "to")}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{
            color: "#ffffff",
            fontWeight: "550",
            padding: 1,
            fontSize: isSmallScreen ? "10px" : "14px",
            mb: 4,
            mr: 2,
          }}
        >
          Cancel
        </Button>
        <Tooltip
          title={
            isFormValid ? "" : "fill in required fields using autocompletion"
          }
        >
          <div>
            <Button
              disabled={!isFormValid}
              onClick={() => {
                onSubmit();
              }}
              variant="contained"
              color="primary"
              sx={{
                color: "#ffffff",
                fontWeight: "550",
                padding: 1,
                fontSize: isSmallScreen ? "10px" : "14px",
                mb: 4,
              }}
            >
              Submit
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddAddress;
