export enum ServerErrorCodesEnum {
  PROFILE_DEACTIVATED = "login.profile_deactivated",
  PERMISSION_DENIED = "permission_denied",
  NOT_FOUND = "not_found",
  NOT_AUTHENTICATED = "not_authenticated",
  TOKEN_NOT_VALID = "jwt.token_not_valid",
}

export enum HookState {
  FETCHING = "fetching",
  ERROR = "error",
  SUCCESS = "success",
  EMPTY = "empty",
  IDLE = "idle",
}

export enum UserRole {
  CUSTOMER = "customer",
  HOLDER = "holder",
}

export enum OrderStatus {
  AWAITING_DELIVERY = "new",
  READY_FOR_PICKUP = "pickupReady",
  DELIVERED = "completed",
  PICKEDUP = "pickedUp",
  CANCELLED = "CANCELLED",
  INDELIVERY = "inDelivery",
  LOST = "lost",
  PAYMENTMETHOD_REQUIRED = "paymentMethodRequired",
  PAID_OUT = "paidOut",
}


export enum Country {
  CANADA = "ca",
  US = "us",
}
